import React from 'react';
import { Clock, Mail, MessageSquare, FileText, User } from 'lucide-react';

const ActivityLog = () => {
  const activities = [
    {
      id: 1,
      type: 'email',
      icon: Mail,
      color: 'text-blue-500',
      bg: 'bg-blue-100',
      title: 'Welcome Email Sent',
      description: 'Automated welcome email sent to john.martinez@email.com',
      timestamp: '2 hours ago',
      user: 'System',
    },
    {
      id: 2,
      type: 'document',
      icon: FileText,
      color: 'text-green-500',
      bg: 'bg-green-100',
      title: 'Document Uploaded',
      description: 'Driver\'s License verification document uploaded',
      timestamp: '4 hours ago',
      user: 'John Martinez',
    },
    {
      id: 3,
      type: 'message',
      icon: MessageSquare,
      color: 'text-purple-500',
      bg: 'bg-purple-100',
      title: 'SMS Notification',
      description: 'Reminder sent for pending medical examination',
      timestamp: '1 day ago',
      user: 'System',
    },
    {
      id: 4,
      type: 'status',
      icon: User,
      color: 'text-yellow-500',
      bg: 'bg-yellow-100',
      title: 'Status Update',
      description: 'Background check process initiated',
      timestamp: '2 days ago',
      user: 'Admin',
    },
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-lg font-semibold">Activity Log</h2>
          <p className="text-sm text-gray-600 mt-1">
            Track all onboarding activities and system events
          </p>
        </div>

        <div className="divide-y divide-gray-200">
          {activities.map((activity) => (
            <div key={activity.id} className="p-6 hover:bg-gray-50">
              <div className="flex items-center">
                <div className={`${activity.bg} p-2 rounded-full`}>
                  <activity.icon className={`w-5 h-5 ${activity.color}`} />
                </div>
                <div className="ml-4 flex-1">
                  <div className="flex items-center justify-between">
                    <h3 className="text-sm font-medium text-gray-900">
                      {activity.title}
                    </h3>
                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                      <Clock className="w-4 h-4" />
                      <span>{activity.timestamp}</span>
                    </div>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {activity.description}
                  </p>
                  <div className="mt-2 text-sm text-gray-500">
                    Performed by: {activity.user}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="bg-gray-50 px-6 py-4 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <button className="text-sm text-gray-600 hover:text-gray-900">
              Export Log
            </button>
            <button className="text-sm text-blue-600 hover:text-blue-800">
              View All Activities
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityLog;