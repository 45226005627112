import React, { useState } from 'react';
import { Users, CheckCircle, Clock, AlertCircle, X, AlertTriangle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useRecruitStore } from '../store/recruitStore';
import { motion, AnimatePresence } from 'framer-motion';

const Dashboard = () => {
  const recruits = useRecruitStore((state) => state.recruits);
  const [selectedFilter, setSelectedFilter] = useState<string | null>(null);

  const getAttentionItems = (recruit) => {
    const attentionItems = [];
    recruit.onboardingSteps.forEach(step => {
      const pendingItems = step.items.filter(item => item.status === 'pending');
      if (pendingItems.length > 0) {
        attentionItems.push({
          step: step.title,
          items: pendingItems
        });
      }
    });
    return attentionItems;
  };

  const stats = [
    {
      label: 'Active Recruits',
      value: recruits.length.toString(),
      icon: Users,
      color: 'bg-blue-500',
      filter: 'all',
    },
    {
      label: 'Completed',
      value: recruits.filter(r => r.progress === 100).length.toString(),
      icon: CheckCircle,
      color: 'bg-green-500',
      filter: 'completed',
    },
    {
      label: 'In Progress',
      value: recruits.filter(r => r.progress > 0 && r.progress < 100).length.toString(),
      icon: Clock,
      color: 'bg-yellow-500',
      filter: 'in-progress',
    },
    {
      label: 'Attention Needed',
      value: recruits.filter(r => r.progress === 0).length.toString(),
      icon: AlertCircle,
      color: 'bg-red-500',
      filter: 'attention',
    },
  ];

  const filteredRecruits = recruits
    .filter(recruit => {
      if (!selectedFilter) return true;
      switch (selectedFilter) {
        case 'completed':
          return recruit.progress === 100;
        case 'in-progress':
          return recruit.progress > 0 && recruit.progress < 100;
        case 'attention':
          return recruit.progress === 0;
        case 'all':
          return true;
        default:
          return true;
      }
    })
    .sort((a, b) => new Date(b.dateJoined).getTime() - new Date(a.dateJoined).getTime());

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat) => (
          <motion.button
            key={stat.label}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            onClick={() => setSelectedFilter(stat.filter)}
            className={`bg-white rounded-lg shadow p-6 transition-all duration-200 ${
              selectedFilter === stat.filter ? 'ring-2 ring-offset-2 ring-blue-500' : ''
            }`}
          >
            <div className="flex items-center justify-between">
              <div>
                <p className="text-sm text-gray-600">{stat.label}</p>
                <p className="text-3xl font-bold mt-1">{stat.value}</p>
              </div>
              <div className={`${stat.color} p-3 rounded-full`}>
                <stat.icon className="w-6 h-6 text-white" />
              </div>
            </div>
          </motion.button>
        ))}
      </div>

      <AnimatePresence>
        {selectedFilter && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="bg-white rounded-lg shadow"
          >
            <div className="p-6 border-b border-gray-200 flex items-center justify-between">
              <h2 className="text-lg font-semibold">
                {selectedFilter === 'all'
                  ? 'All Recruits'
                  : selectedFilter === 'completed'
                  ? 'Completed Onboarding'
                  : selectedFilter === 'in-progress'
                  ? 'Onboarding In Progress'
                  : 'Needs Attention'}
              </h2>
              <button
                onClick={() => setSelectedFilter(null)}
                className="text-gray-400 hover:text-gray-500"
              >
                <X className="w-5 h-5" />
              </button>
            </div>
            <div className="divide-y divide-gray-200">
              {filteredRecruits.length > 0 ? (
                filteredRecruits.map((recruit) => (
                  <div key={recruit.id} className="p-6 hover:bg-gray-50 transition-colors duration-150">
                    <Link
                      to={`/onboarding/${recruit.id}`}
                      className="block"
                    >
                      <div className="flex items-center justify-between group mb-4">
                        <div className="flex items-center space-x-4">
                          <img
                            src={recruit.photo || 'https://images.unsplash.com/photo-1599566150163-29194dcaad36?auto=format&fit=crop&q=80&w=100&h=100'}
                            alt={`${recruit.firstName} ${recruit.lastName}`}
                            className="w-12 h-12 rounded-full object-cover"
                          />
                          <div>
                            <h3 className="font-medium group-hover:text-red-600 transition-colors">
                              {recruit.firstName} {recruit.lastName}
                            </h3>
                            <p className="text-sm text-gray-500">{recruit.role}</p>
                          </div>
                        </div>
                        <div className="text-right">
                          <p className="text-sm text-gray-600">{recruit.status}</p>
                          <div className="mt-2 flex items-center">
                            <div className="w-48 bg-gray-200 rounded-full h-2">
                              <div
                                className="bg-blue-600 h-2 rounded-full"
                                style={{ width: `${recruit.progress}%` }}
                              />
                            </div>
                            <span className="ml-2 text-sm text-gray-600">
                              {recruit.progress}%
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                    
                    {selectedFilter === 'attention' && (
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        className="mt-4 bg-red-50 rounded-lg p-4"
                      >
                        <div className="flex items-center space-x-2 mb-3">
                          <AlertTriangle className="w-5 h-5 text-red-500" />
                          <h4 className="font-medium text-red-700">Items Requiring Attention</h4>
                        </div>
                        <div className="space-y-4">
                          {getAttentionItems(recruit).map((section, index) => (
                            <div key={index} className="pl-4 border-l-2 border-red-200">
                              <h5 className="text-sm font-medium text-red-700 mb-2">
                                {section.step}
                              </h5>
                              <ul className="space-y-1">
                                {section.items.map((item, itemIndex) => (
                                  <li
                                    key={itemIndex}
                                    className="text-sm text-red-600 flex items-center space-x-2"
                                  >
                                    <span className="w-1.5 h-1.5 bg-red-400 rounded-full"></span>
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </div>
                ))
              ) : (
                <div className="p-6 text-center text-gray-500">
                  No recruits found in this category.
                </div>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      {!selectedFilter && (
        <div className="bg-white rounded-lg shadow">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Recent Recruits</h2>
          </div>
          <div className="divide-y divide-gray-200">
            {recruits.length > 0 ? (
              recruits
                .slice(0, 3)
                .map((recruit) => (
                  <Link
                    key={recruit.id}
                    to={`/onboarding/${recruit.id}`}
                    className="block p-6 hover:bg-gray-50 transition-colors duration-150"
                  >
                    <div className="flex items-center justify-between group">
                      <div className="flex items-center space-x-4">
                        <img
                          src={recruit.photo || 'https://images.unsplash.com/photo-1599566150163-29194dcaad36?auto=format&fit=crop&q=80&w=100&h=100'}
                          alt={`${recruit.firstName} ${recruit.lastName}`}
                          className="w-12 h-12 rounded-full object-cover"
                        />
                        <div>
                          <h3 className="font-medium group-hover:text-red-600 transition-colors">
                            {recruit.firstName} {recruit.lastName}
                          </h3>
                          <p className="text-sm text-gray-500">{recruit.role}</p>
                        </div>
                      </div>
                      <div className="text-right">
                        <p className="text-sm text-gray-600">{recruit.status}</p>
                        <div className="mt-2 flex items-center">
                          <div className="w-48 bg-gray-200 rounded-full h-2">
                            <div
                              className="bg-blue-600 h-2 rounded-full"
                              style={{ width: `${recruit.progress}%` }}
                            />
                          </div>
                          <span className="ml-2 text-sm text-gray-600">
                            {recruit.progress}%
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                ))
            ) : (
              <div className="p-6 text-center text-gray-500">
                No recruits added yet. Click "Add New Recruit" to get started.
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;