import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface AuthState {
  isAuthenticated: boolean;
  user: {
    email: string;
    role: 'admin' | 'scheduler';
  } | null;
  login: (email: string, password: string) => Promise<boolean>;
  logout: () => void;
}

// In a real app, these would be in a secure backend
const VALID_CREDENTIALS = {
  admin: {
    email: 'admin@firedept.gov',
    password: 'Admin123!',
  },
  scheduler: {
    email: 'scheduler@firedept.gov',
    password: 'Schedule123!',
  },
};

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      isAuthenticated: false,
      user: null,
      login: async (email: string, password: string) => {
        // Simulate API call
        await new Promise((resolve) => setTimeout(resolve, 1000));

        if (
          email === VALID_CREDENTIALS.admin.email &&
          password === VALID_CREDENTIALS.admin.password
        ) {
          set({
            isAuthenticated: true,
            user: {
              email: VALID_CREDENTIALS.admin.email,
              role: 'admin',
            },
          });
          return true;
        }

        if (
          email === VALID_CREDENTIALS.scheduler.email &&
          password === VALID_CREDENTIALS.scheduler.password
        ) {
          set({
            isAuthenticated: true,
            user: {
              email: VALID_CREDENTIALS.scheduler.email,
              role: 'scheduler',
            },
          });
          return true;
        }

        return false;
      },
      logout: () => {
        set({
          isAuthenticated: false,
          user: null,
        });
      },
    }),
    {
      name: 'auth-storage',
    }
  )
);