import React, { useState } from 'react';
import { Calendar, Clock, Users, Truck, AlertCircle, CheckCircle, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useApparatusStore } from '../store/apparatusStore';

const ApparatusSchedule = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState<any>(null);
  const { apparatus, shifts, signUpForShift } = useApparatusStore();

  const handleSignUp = (apparatusId: string, position: string) => {
    setSelectedSlot({ apparatusId, position });
    setShowSignUpModal(true);
  };

  const [formData, setFormData] = useState({
    startTime: '07:00',
    endTime: '19:00',
    shift: 'Day',
  });

  const confirmSignUp = () => {
    if (selectedSlot) {
      signUpForShift(
        selectedSlot.apparatusId,
        selectedSlot.position,
        selectedDate,
        formData.startTime,
        formData.endTime,
        formData.shift
      );
      setShowSignUpModal(false);
    }
  };

  const getPositionStatus = (apparatusId: string, position: string) => {
    return shifts.find(
      (s) =>
        s.apparatusId === apparatusId &&
        s.position === position &&
        new Date(s.date).toDateString() === selectedDate.toDateString()
    );
  };

  const shiftTimes = {
    Day: { start: '07:00', end: '19:00' },
    Night: { start: '19:00', end: '07:00' },
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div>
              <h2 className="text-lg font-semibold">Apparatus Schedule</h2>
              <p className="text-sm text-gray-600">Sign up for apparatus positions</p>
            </div>
            <div className="flex items-center space-x-4">
              <input
                type="date"
                value={selectedDate.toISOString().split('T')[0]}
                onChange={(e) => setSelectedDate(new Date(e.target.value))}
                className="px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
              />
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="grid gap-6">
            {apparatus.map((unit) => (
              <motion.div
                key={unit.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-50 rounded-lg p-6"
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center space-x-4">
                    <div className="bg-red-100 p-2 rounded-lg">
                      <Truck className="w-6 h-6 text-red-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium">{unit.name}</h3>
                      <p className="text-sm text-gray-500">{unit.type}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <Users className="w-4 h-4 text-gray-400" />
                    <span className="text-sm text-gray-600">
                      Positions: {unit.positions.length}
                    </span>
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {['Day', 'Night'].map((shift) => (
                    <div
                      key={shift}
                      className="bg-white rounded-lg p-4 border border-gray-200"
                    >
                      <div className="flex items-center justify-between mb-3">
                        <div className="flex items-center space-x-2">
                          <Clock className="w-4 h-4 text-gray-400" />
                          <span className="font-medium">
                            {shift} Shift ({shiftTimes[shift].start} - {shiftTimes[shift].end})
                          </span>
                        </div>
                      </div>

                      <div className="space-y-4">
                        {unit.positions.map((position) => {
                          const status = getPositionStatus(unit.id, position);
                          return (
                            <div
                              key={position}
                              className="flex items-center justify-between p-2 bg-gray-50 rounded-lg"
                            >
                              <div>
                                <p className="font-medium text-sm">{position}</p>
                                {status ? (
                                  <div className="text-sm text-gray-600">
                                    <p>{status.memberName}</p>
                                    <p className="text-xs">
                                      {status.startTime} - {status.endTime}
                                    </p>
                                  </div>
                                ) : (
                                  <p className="text-xs text-gray-500">Available</p>
                                )}
                              </div>
                              {!status && (
                                <motion.button
                                  whileHover={{ scale: 1.02 }}
                                  whileTap={{ scale: 0.98 }}
                                  onClick={() => handleSignUp(unit.id, position)}
                                  className="px-3 py-1 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
                                >
                                  Sign Up
                                </motion.button>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>

      {/* Sign Up Modal */}
      <AnimatePresence>
        {showSignUpModal && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
          >
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="bg-white rounded-lg shadow-xl max-w-md w-full p-6"
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Sign Up for Shift</h3>
                <button
                  onClick={() => setShowSignUpModal(false)}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <X className="w-5 h-5" />
                </button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Shift Type
                  </label>
                  <select
                    value={formData.shift}
                    onChange={(e) => setFormData({ ...formData, shift: e.target.value })}
                    className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                  >
                    <option value="Day">Day Shift (07:00 - 19:00)</option>
                    <option value="Night">Night Shift (19:00 - 07:00)</option>
                  </select>
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      Start Time
                    </label>
                    <input
                      type="time"
                      value={formData.startTime}
                      onChange={(e) =>
                        setFormData({ ...formData, startTime: e.target.value })
                      }
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">
                      End Time
                    </label>
                    <input
                      type="time"
                      value={formData.endTime}
                      onChange={(e) =>
                        setFormData({ ...formData, endTime: e.target.value })
                      }
                      className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                    />
                  </div>
                </div>

                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="font-medium mb-2">Position Details</h4>
                  <p className="text-sm text-gray-600">
                    {apparatus.find((a) => a.id === selectedSlot?.apparatusId)?.name} -{' '}
                    {selectedSlot?.position}
                  </p>
                  <p className="text-sm text-gray-600">
                    Date: {selectedDate.toLocaleDateString()}
                  </p>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowSignUpModal(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  onClick={confirmSignUp}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700"
                >
                  Confirm Sign Up
                </button>
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ApparatusSchedule;