import React, { useState } from 'react';
import { Mail, Save, Play, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const EmailTemplates = () => {
  const [selectedTemplate, setSelectedTemplate] = useState('welcome');
  const [content, setContent] = useState({
    welcome: {
      subject: 'Welcome to the Fire Department! 🚒',
      body: `Dear {firstName},

Welcome to our firefighting family! We're thrilled to have you join our team of dedicated first responders.

Here's what you need to know to get started:
- Your orientation begins on {startDate}
- Please bring your ID and certifications
- Your mentor will be {mentorName}

Stay brave and ready!

Best regards,
{departmentName}`,
    },
    reminder: {
      subject: 'Action Required: Onboarding Task Reminder 📋',
      body: `Hi {firstName},

This is a friendly reminder about your pending onboarding tasks:
{pendingTasks}

Please complete these as soon as possible to stay on track.

Need help? Contact your mentor or HR team.

Best regards,
{departmentName}`,
    },
  });

  const handleSave = () => {
    // Simulate save with animation
  };

  const handlePreview = () => {
    // Show preview in modal
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="border-b border-gray-200">
          <div className="flex items-center justify-between p-6">
            <div className="flex items-center space-x-3">
              <Link
                to="/settings"
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </Link>
              <div>
                <h2 className="text-lg font-semibold">Email Templates</h2>
                <p className="text-sm text-gray-600">
                  Customize automated email communications
                </p>
              </div>
            </div>
          </div>
          <div className="px-6 border-b border-gray-200">
            <div className="flex space-x-4">
              <button
                onClick={() => setSelectedTemplate('welcome')}
                className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors ${
                  selectedTemplate === 'welcome'
                    ? 'border-red-500 text-red-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                Welcome Email
              </button>
              <button
                onClick={() => setSelectedTemplate('reminder')}
                className={`px-4 py-2 text-sm font-medium border-b-2 transition-colors ${
                  selectedTemplate === 'reminder'
                    ? 'border-red-500 text-red-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700'
                }`}
              >
                Reminder Email
              </button>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="space-y-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Subject Line
              </label>
              <input
                type="text"
                value={content[selectedTemplate].subject}
                onChange={(e) =>
                  setContent({
                    ...content,
                    [selectedTemplate]: {
                      ...content[selectedTemplate],
                      subject: e.target.value,
                    },
                  })
                }
                className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Email Content
              </label>
              <div className="relative">
                <textarea
                  value={content[selectedTemplate].body}
                  onChange={(e) =>
                    setContent({
                      ...content,
                      [selectedTemplate]: {
                        ...content[selectedTemplate],
                        body: e.target.value,
                      },
                    })
                  }
                  rows={12}
                  className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500 font-mono text-sm"
                />
                <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                  Use {'{variable}'} for dynamic content
                </div>
              </div>
            </div>

            <div className="flex justify-between items-center pt-4">
              <div className="text-sm text-gray-600">
                <Mail className="inline-block w-4 h-4 mr-1" />
                Last modified: Today at 2:30 PM
              </div>
              <div className="flex space-x-3">
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handlePreview}
                  className="flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <Play className="w-4 h-4 mr-2" />
                  Preview
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={handleSave}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <Save className="w-4 h-4 mr-2" />
                  Save Changes
                </motion.button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailTemplates;