import React from 'react';
import { Bell, Mail, Shield, Building, Users, FileText, Settings as SettingsIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Settings = () => {
  const sections = [
    {
      title: 'Notifications',
      icon: Bell,
      description: 'Configure how you receive alerts and updates',
      link: '/settings/notifications',
      settings: [
        {
          name: 'Email Notifications',
          description: 'Receive email updates about recruit progress',
          enabled: true,
        },
        {
          name: 'SMS Alerts',
          description: 'Get text messages for urgent updates',
          enabled: false,
        },
      ],
    },
    {
      title: 'Email Templates',
      icon: Mail,
      description: 'Customize automated email communications',
      link: '/settings/email-templates',
      settings: [
        {
          name: 'Welcome Email',
          description: 'Edit the welcome email template',
          type: 'template',
        },
        {
          name: 'Reminder Email',
          description: 'Edit the reminder email template',
          type: 'template',
        },
      ],
    },
    {
      title: 'Security',
      icon: Shield,
      description: 'Manage account security and sessions',
      link: '/settings/security',
      settings: [
        {
          name: 'Two-Factor Authentication',
          description: 'Add an extra layer of security',
          enabled: true,
        },
        {
          name: 'Session Management',
          description: 'Manage active sessions',
          type: 'action',
        },
      ],
    },
    {
      title: 'Affiliate Organizations',
      icon: Building,
      description: 'Manage partnerships with hospitals and training centers',
      link: '/settings/affiliates',
      settings: [
        {
          name: 'Partner Facilities',
          description: 'View and manage affiliated organizations',
          type: 'action',
        },
        {
          name: 'Integration Settings',
          description: 'Configure data sharing and access',
          type: 'action',
        },
      ],
    },
    {
      title: 'Department Settings',
      icon: Users,
      description: 'Configure department-wide preferences',
      link: '/settings/department',
      settings: [
        {
          name: 'Department Profile',
          description: 'Update department information',
          type: 'action',
        },
        {
          name: 'Roles & Permissions',
          description: 'Manage access levels',
          type: 'action',
        },
      ],
    },
    {
      title: 'Documentation',
      icon: FileText,
      description: 'Manage forms and required documents',
      link: '/settings/documentation',
      settings: [
        {
          name: 'Form Templates',
          description: 'Customize onboarding documents',
          type: 'action',
        },
        {
          name: 'Required Documents',
          description: 'Set documentation requirements',
          type: 'action',
        },
      ],
    },
  ];

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center space-x-3">
            <div className="p-2 bg-red-100 rounded-lg">
              <SettingsIcon className="w-5 h-5 text-red-600" />
            </div>
            <div>
              <h2 className="text-lg font-semibold">Settings</h2>
              <p className="text-sm text-gray-600">
                Manage your application preferences and configurations
              </p>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200">
          {sections.map((section) => (
            <motion.div
              key={section.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="p-6 hover:bg-gray-50 transition-colors"
            >
              <Link to={section.link} className="block">
                <div className="flex items-center mb-4">
                  <section.icon className="w-5 h-5 text-gray-400 mr-3" />
                  <div>
                    <h3 className="text-lg font-medium text-gray-900 group-hover:text-red-600">
                      {section.title}
                    </h3>
                    <p className="text-sm text-gray-500">{section.description}</p>
                  </div>
                </div>
                <div className="ml-8 space-y-4">
                  {section.settings.map((setting) => (
                    <div
                      key={setting.name}
                      className="flex items-center justify-between"
                    >
                      <div>
                        <p className="text-sm font-medium text-gray-900">
                          {setting.name}
                        </p>
                        <p className="text-sm text-gray-500">
                          {setting.description}
                        </p>
                      </div>
                      {'enabled' in setting ? (
                        <div
                          className={`relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out ${
                            setting.enabled ? 'bg-red-600' : 'bg-gray-200'
                          }`}
                        >
                          <span
                            className={`inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                              setting.enabled ? 'translate-x-5' : 'translate-x-0'
                            }`}
                          />
                        </div>
                      ) : (
                        <span className="text-sm text-blue-600 hover:text-blue-800">
                          Configure
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </Link>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Settings;