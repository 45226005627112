import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Building, ArrowLeft, Plus, Trash2, Edit2, ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';

const AffiliateSettings = () => {
  const [affiliates, setAffiliates] = useState([
    {
      id: 1,
      name: 'Central Hospital',
      type: 'Medical Facility',
      contact: 'Dr. Sarah Johnson',
      phone: '(555) 123-4567',
      email: 'sjohnson@centralhospital.com',
      status: 'active',
    },
    {
      id: 2,
      name: 'City Police Department',
      type: 'Law Enforcement',
      contact: 'Chief Michael Brown',
      phone: '(555) 234-5678',
      email: 'mbrown@citypd.gov',
      status: 'active',
    },
    {
      id: 3,
      name: 'Regional Training Center',
      type: 'Training Facility',
      contact: 'James Wilson',
      phone: '(555) 345-6789',
      email: 'jwilson@rtc.edu',
      status: 'pending',
    },
  ]);

  const [showAddModal, setShowAddModal] = useState(false);

  return (
    <div className="max-w-6xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-3">
              <Link
                to="/settings"
                className="text-gray-500 hover:text-gray-700 transition-colors"
              >
                <ArrowLeft className="w-5 h-5" />
              </Link>
              <div>
                <h2 className="text-lg font-semibold">Affiliate Organizations</h2>
                <p className="text-sm text-gray-600">
                  Manage partnerships with hospitals, training centers, and other agencies
                </p>
              </div>
            </div>
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => setShowAddModal(true)}
              className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Plus className="w-4 h-4 mr-2" />
              Add Affiliate
            </motion.button>
          </div>
        </div>

        <div className="p-6">
          <div className="grid grid-cols-1 gap-6">
            {affiliates.map((affiliate) => (
              <motion.div
                key={affiliate.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-gray-50 rounded-lg p-6 hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <div className="bg-white p-2 rounded-lg shadow">
                      <Building className="w-6 h-6 text-red-600" />
                    </div>
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">
                        {affiliate.name}
                      </h3>
                      <p className="text-sm text-gray-500">{affiliate.type}</p>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span
                      className={`px-2 py-1 text-xs font-medium rounded-full ${
                        affiliate.status === 'active'
                          ? 'bg-green-100 text-green-800'
                          : 'bg-yellow-100 text-yellow-800'
                      }`}
                    >
                      {affiliate.status.charAt(0).toUpperCase() + affiliate.status.slice(1)}
                    </span>
                  </div>
                </div>

                <div className="mt-4 grid grid-cols-2 gap-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">Contact Person</p>
                    <p className="mt-1 text-sm text-gray-900">{affiliate.contact}</p>
                  </div>
                  <div>
                    <p className="text-sm font-medium text-gray-500">Phone</p>
                    <p className="mt-1 text-sm text-gray-900">{affiliate.phone}</p>
                  </div>
                  <div className="col-span-2">
                    <p className="text-sm font-medium text-gray-500">Email</p>
                    <p className="mt-1 text-sm text-gray-900">{affiliate.email}</p>
                  </div>
                </div>

                <div className="mt-4 flex justify-end space-x-3">
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="flex items-center px-3 py-1.5 text-sm text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    <Edit2 className="w-4 h-4 mr-1.5" />
                    Edit
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="flex items-center px-3 py-1.5 text-sm text-blue-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    <ExternalLink className="w-4 h-4 mr-1.5" />
                    Visit Portal
                  </motion.button>
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    className="flex items-center px-3 py-1.5 text-sm text-red-600 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    <Trash2 className="w-4 h-4 mr-1.5" />
                    Remove
                  </motion.button>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateSettings;