import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Siren, UserPlus } from 'lucide-react';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';
import OnboardingFlow from './components/OnboardingFlow';
import ActivityLog from './components/ActivityLog';
import RecruitList from './components/RecruitList';
import Settings from './components/Settings';
import NotificationSettings from './components/settings/NotificationSettings';
import EmailTemplates from './components/settings/EmailTemplates';
import SecuritySettings from './components/settings/SecuritySettings';
import AffiliateSettings from './components/settings/AffiliateSettings';
import ApparatusSchedule from './components/ApparatusSchedule';
import Login from './components/Login';
import NewRecruitModal from './components/NewRecruitModal';
import { useAuthStore } from './store/authStore';

const App = () => {
  const { isAuthenticated, user, logout } = useAuthStore();
  const [isNewRecruitModalOpen, setIsNewRecruitModalOpen] = useState(false);

  if (!isAuthenticated) {
    return <Login />;
  }

  // Scheduler role only has access to the scheduling page
  if (user?.role === 'scheduler') {
    return (
      <BrowserRouter>
        <div className="flex min-h-screen bg-gray-50">
          <div className="w-64 bg-gray-900 text-white">
            <div className="p-6">
              <div className="flex items-center space-x-3">
                <div className="bg-red-600 p-2 rounded-lg">
                  <Siren className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h2 className="font-bold">Station 42</h2>
                  <p className="text-sm text-gray-400">Scheduling Portal</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <header className="bg-white shadow-sm">
              <div className="flex items-center justify-between px-8 py-4">
                <div className="flex items-center space-x-3">
                  <Siren className="h-8 w-8 text-red-600" />
                  <h1 className="text-2xl font-bold text-gray-900">Ready to Ride</h1>
                </div>
                <button
                  onClick={logout}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Sign Out
                </button>
              </div>
            </header>
            <main className="p-8">
              <Routes>
                <Route path="/apparatus" element={<ApparatusSchedule />} />
                <Route path="*" element={<Navigate to="/apparatus" replace />} />
              </Routes>
            </main>
          </div>
        </div>
      </BrowserRouter>
    );
  }

  // Admin role has access to everything
  return (
    <BrowserRouter>
      <div className="flex min-h-screen bg-gray-50">
        <Sidebar />
        <div className="flex-1">
          <header className="bg-white shadow-sm">
            <div className="flex items-center justify-between px-8 py-4">
              <div className="flex items-center space-x-3">
                <Siren className="h-8 w-8 text-red-600" />
                <h1 className="text-2xl font-bold text-gray-900">Ready to Ride</h1>
              </div>
              <div className="flex items-center space-x-4">
                <button
                  onClick={() => setIsNewRecruitModalOpen(true)}
                  className="flex items-center px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  <UserPlus className="w-4 h-4 mr-2" />
                  Add New Recruit
                </button>
                <button
                  onClick={logout}
                  className="text-sm text-gray-600 hover:text-gray-900"
                >
                  Sign Out
                </button>
              </div>
            </div>
          </header>
          <main className="p-8">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/onboarding/:id" element={<OnboardingFlow />} />
              <Route path="/activity" element={<ActivityLog />} />
              <Route path="/recruits" element={<RecruitList />} />
              <Route path="/apparatus" element={<ApparatusSchedule />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/settings/notifications" element={<NotificationSettings />} />
              <Route path="/settings/email-templates" element={<EmailTemplates />} />
              <Route path="/settings/security" element={<SecuritySettings />} />
              <Route path="/settings/affiliates" element={<AffiliateSettings />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </main>
        </div>
        <NewRecruitModal
          isOpen={isNewRecruitModalOpen}
          onClose={() => setIsNewRecruitModalOpen(false)}
        />
      </div>
    </BrowserRouter>
  );
};

export default App;