import React, { useState } from 'react';
import { Shield, Key, Smartphone, LogOut, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const SecuritySettings = () => {
  const [showQRCode, setShowQRCode] = useState(false);
  const [sessions] = useState([
    {
      device: 'Chrome on Windows',
      location: 'New York, USA',
      lastActive: 'Active now',
      current: true,
    },
    {
      device: 'Firefox on MacOS',
      location: 'Boston, USA',
      lastActive: '2 hours ago',
      current: false,
    },
    {
      device: 'Safari on iPhone',
      location: 'Miami, USA',
      lastActive: '1 day ago',
      current: false,
    },
  ]);

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center space-x-3">
            <Link
              to="/settings"
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
            </Link>
            <div>
              <h2 className="text-lg font-semibold">Security Settings</h2>
              <p className="text-sm text-gray-600">
                Manage your account security and sessions
              </p>
            </div>
          </div>
        </div>

        <div className="p-6 space-y-8">
          {/* Two-Factor Authentication */}
          <div>
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center space-x-3">
                <Shield className="w-5 h-5 text-gray-400" />
                <h3 className="text-lg font-medium">Two-Factor Authentication</h3>
              </div>
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                onClick={() => setShowQRCode(!showQRCode)}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {showQRCode ? 'Hide QR Code' : 'Setup 2FA'}
              </motion.button>
            </div>
            {showQRCode && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                className="bg-gray-50 p-6 rounded-lg mt-4"
              >
                <div className="flex items-center space-x-6">
                  <div className="w-48 h-48 bg-white p-4 rounded-lg shadow-sm">
                    {/* Placeholder for QR Code */}
                    <div className="w-full h-full border-2 border-dashed border-gray-300 rounded flex items-center justify-center">
                      <span className="text-gray-400">QR Code</span>
                    </div>
                  </div>
                  <div className="flex-1">
                    <h4 className="font-medium mb-2">Setup Instructions</h4>
                    <ol className="list-decimal list-inside space-y-2 text-sm text-gray-600">
                      <li>Download an authenticator app</li>
                      <li>Scan the QR code with the app</li>
                      <li>Enter the 6-digit code below</li>
                    </ol>
                    <div className="mt-4">
                      <input
                        type="text"
                        placeholder="Enter verification code"
                        className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-red-500 focus:border-red-500"
                      />
                    </div>
                  </div>
                </div>
              </motion.div>
            )}
          </div>

          {/* Active Sessions */}
          <div>
            <div className="flex items-center space-x-3 mb-4">
              <Key className="w-5 h-5 text-gray-400" />
              <h3 className="text-lg font-medium">Active Sessions</h3>
            </div>
            <div className="space-y-4">
              {sessions.map((session, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between p-4 bg-gray-50 rounded-lg"
                >
                  <div className="flex items-center space-x-4">
                    <Smartphone className="w-5 h-5 text-gray-400" />
                    <div>
                      <p className="font-medium text-gray-900">
                        {session.device}
                        {session.current && (
                          <span className="ml-2 text-xs text-green-600 bg-green-100 px-2 py-0.5 rounded-full">
                            Current
                          </span>
                        )}
                      </p>
                      <p className="text-sm text-gray-500">
                        {session.location} • {session.lastActive}
                      </p>
                    </div>
                  </div>
                  {!session.current && (
                    <motion.button
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                      className="text-sm text-red-600 hover:text-red-700"
                    >
                      Revoke
                    </motion.button>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="pt-6 border-t border-gray-200">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-lg shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <LogOut className="w-4 h-4 mr-2" />
              Sign Out All Other Sessions
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecuritySettings;