import React, { useState } from 'react';
import { Bell, Mail, MessageSquare, ArrowLeft } from 'lucide-react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const NotificationSettings = () => {
  const [notifications, setNotifications] = useState({
    email: {
      onboardingUpdates: true,
      taskReminders: true,
      documentExpiry: true,
      weeklyReports: false,
    },
    sms: {
      urgentAlerts: true,
      scheduleChanges: true,
      certificationReminders: false,
    },
  });

  const toggleNotification = (type: 'email' | 'sms', key: string) => {
    setNotifications({
      ...notifications,
      [type]: {
        ...notifications[type],
        [key]: !notifications[type][key],
      },
    });
  };

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <div className="flex items-center space-x-3">
            <Link
              to="/settings"
              className="text-gray-500 hover:text-gray-700 transition-colors"
            >
              <ArrowLeft className="w-5 h-5" />
            </Link>
            <div>
              <h2 className="text-lg font-semibold">Notification Preferences</h2>
              <p className="text-sm text-gray-600">
                Manage how you receive updates and alerts
              </p>
            </div>
          </div>
        </div>

        <div className="p-6 space-y-8">
          {/* Email Notifications */}
          <div>
            <div className="flex items-center space-x-3 mb-6">
              <Mail className="w-5 h-5 text-gray-400" />
              <h3 className="text-lg font-medium">Email Notifications</h3>
            </div>
            <div className="space-y-4">
              {Object.entries(notifications.email).map(([key, enabled]) => (
                <div
                  key={key}
                  className="flex items-center justify-between py-2 hover:bg-gray-50 px-4 rounded-lg transition-colors"
                >
                  <div>
                    <p className="font-medium text-gray-900">
                      {key
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => str.toUpperCase())}
                    </p>
                    <p className="text-sm text-gray-500">
                      Receive updates about{' '}
                      {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                    </p>
                  </div>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    onClick={() => toggleNotification('email', key)}
                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${
                      enabled ? 'bg-red-600' : 'bg-gray-200'
                    }`}
                  >
                    <span
                      className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        enabled ? 'translate-x-5' : 'translate-x-0'
                      }`}
                    />
                  </motion.button>
                </div>
              ))}
            </div>
          </div>

          {/* SMS Notifications */}
          <div>
            <div className="flex items-center space-x-3 mb-6">
              <MessageSquare className="w-5 h-5 text-gray-400" />
              <h3 className="text-lg font-medium">SMS Notifications</h3>
            </div>
            <div className="space-y-4">
              {Object.entries(notifications.sms).map(([key, enabled]) => (
                <div
                  key={key}
                  className="flex items-center justify-between py-2 hover:bg-gray-50 px-4 rounded-lg transition-colors"
                >
                  <div>
                    <p className="font-medium text-gray-900">
                      {key
                        .replace(/([A-Z])/g, ' $1')
                        .replace(/^./, (str) => str.toUpperCase())}
                    </p>
                    <p className="text-sm text-gray-500">
                      Get SMS alerts for{' '}
                      {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                    </p>
                  </div>
                  <motion.button
                    whileTap={{ scale: 0.95 }}
                    onClick={() => toggleNotification('sms', key)}
                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ${
                      enabled ? 'bg-red-600' : 'bg-gray-200'
                    }`}
                  >
                    <span
                      className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        enabled ? 'translate-x-5' : 'translate-x-0'
                      }`}
                    />
                  </motion.button>
                </div>
              ))}
            </div>
          </div>

          <div className="pt-6 border-t border-gray-200">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-lg shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
            >
              <Bell className="w-4 h-4 mr-2" />
              Update Preferences
            </motion.button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationSettings;