import { create } from 'zustand';
import { persist } from 'zustand/middleware';

export interface Recruit {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  role: string;
  dateJoined: string;
  dateOfBirth: string;
  driversLicense: string;
  photo: string;
  status: string;
  progress: number;
  startDate: string;
  onboardingSteps: {
    title: string;
    status: 'completed' | 'current' | 'pending';
    items: {
      name: string;
      status: 'completed' | 'pending';
      date?: string;
    }[];
  }[];
}

interface RecruitState {
  recruits: Recruit[];
  addRecruit: (recruit: Omit<Recruit, 'id' | 'status' | 'progress' | 'onboardingSteps'>) => void;
  updateRecruit: (id: string, updates: Partial<Recruit>) => void;
  getRecruit: (id: string) => Recruit | undefined;
}

export const useRecruitStore = create<RecruitState>()(
  persist(
    (set, get) => ({
      recruits: [],
      addRecruit: (recruitData) => {
        const newRecruit: Recruit = {
          ...recruitData,
          id: crypto.randomUUID(),
          status: 'Documentation',
          progress: 0,
          onboardingSteps: [
            {
              title: 'Personal Information',
              status: 'current',
              items: [
                { name: 'Basic Details', status: 'completed', date: new Date().toISOString() },
                { name: 'Contact Information', status: 'completed', date: new Date().toISOString() },
                { name: 'Emergency Contacts', status: 'pending' },
              ],
            },
            {
              title: 'Documentation',
              status: 'pending',
              items: [
                { name: 'ID Verification', status: 'pending' },
                { name: 'Certifications', status: 'pending' },
                { name: 'Training Records', status: 'pending' },
              ],
            },
            {
              title: 'Medical Clearance',
              status: 'pending',
              items: [
                { name: 'Physical Examination', status: 'pending' },
                { name: 'Drug Screening', status: 'pending' },
                { name: 'Immunization Records', status: 'pending' },
              ],
            },
            {
              title: 'Department Policies',
              status: 'pending',
              items: [
                { name: 'Code of Conduct', status: 'pending' },
                { name: 'Safety Protocols', status: 'pending' },
                { name: 'Equipment Usage', status: 'pending' },
              ],
            },
          ],
        };

        set((state) => ({
          recruits: [...state.recruits, newRecruit],
        }));
      },
      updateRecruit: (id, updates) => {
        set((state) => ({
          recruits: state.recruits.map((recruit) =>
            recruit.id === id ? { ...recruit, ...updates } : recruit
          ),
        }));
      },
      getRecruit: (id) => {
        return get().recruits.find((recruit) => recruit.id === id);
      },
    }),
    {
      name: 'recruit-storage',
    }
  )
);