import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface Apparatus {
  id: string;
  name: string;
  type: string;
  crewRequired: number;
  positions: string[];
}

interface Shift {
  id: string;
  apparatusId: string;
  memberId: string;
  memberName: string;
  position: string;
  date: string;
  startTime: string;
  endTime: string;
  shift: string;
}

interface ApparatusState {
  apparatus: Apparatus[];
  shifts: Shift[];
  addApparatus: (apparatus: Omit<Apparatus, 'id'>) => void;
  removeApparatus: (id: string) => void;
  signUpForShift: (
    apparatusId: string,
    position: string,
    date: Date,
    startTime: string,
    endTime: string,
    shift: string
  ) => void;
  cancelShift: (shiftId: string) => void;
}

export const useApparatusStore = create<ApparatusState>()(
  persist(
    (set) => ({
      apparatus: [
        {
          id: '1',
          name: 'Engine 1',
          type: 'Fire Engine',
          crewRequired: 4,
          positions: ['Officer', 'Driver', 'Firefighter 1', 'Firefighter 2'],
        },
        {
          id: '2',
          name: 'Ladder 1',
          type: 'Ladder Truck',
          crewRequired: 4,
          positions: ['Officer', 'Driver', 'Firefighter 1', 'Firefighter 2'],
        },
        {
          id: '3',
          name: 'Rescue 1',
          type: 'Heavy Rescue',
          crewRequired: 3,
          positions: ['Officer', 'Driver', 'Rescue Tech'],
        },
        {
          id: '4',
          name: 'Medic 1',
          type: 'Ambulance',
          crewRequired: 2,
          positions: ['Paramedic', 'EMT'],
        },
      ],
      shifts: [],
      addApparatus: (newApparatus) =>
        set((state) => ({
          apparatus: [
            ...state.apparatus,
            { ...newApparatus, id: crypto.randomUUID() },
          ],
        })),
      removeApparatus: (id) =>
        set((state) => ({
          apparatus: state.apparatus.filter((a) => a.id !== id),
          shifts: state.shifts.filter((s) => s.apparatusId !== id),
        })),
      signUpForShift: (apparatusId, position, date, startTime, endTime, shift) =>
        set((state) => ({
          shifts: [
            ...state.shifts,
            {
              id: crypto.randomUUID(),
              apparatusId,
              memberId: 'current-user-id',
              memberName: 'John Doe',
              position,
              date: date.toISOString(),
              startTime,
              endTime,
              shift,
            },
          ],
        })),
      cancelShift: (shiftId) =>
        set((state) => ({
          shifts: state.shifts.filter((s) => s.id !== shiftId),
        })),
    }),
    {
      name: 'apparatus-storage',
    }
  )
);