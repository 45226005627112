import React from 'react';
import { useParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { CheckCircle, AlertCircle, Clock } from 'lucide-react';
import { useRecruitStore } from '../store/recruitStore';

const OnboardingFlow = () => {
  const { id } = useParams<{ id: string }>();
  const recruit = useRecruitStore((state) => state.getRecruit(id || ''));

  if (!recruit) {
    return (
      <div className="max-w-4xl mx-auto p-8">
        <div className="bg-red-50 border-l-4 border-red-400 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertCircle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Recruit not found. Please check the ID and try again.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto">
      <div className="bg-white rounded-lg shadow-lg p-6 mb-8">
        <div className="flex items-center space-x-4 mb-6">
          <img
            src={recruit.photo || 'https://via.placeholder.com/100'}
            alt={`${recruit.firstName} ${recruit.lastName}`}
            className="w-16 h-16 rounded-full object-cover"
          />
          <div className="flex-1">
            <h2 className="text-xl font-bold">{`${recruit.firstName} ${recruit.lastName}`}</h2>
            <p className="text-gray-600">{recruit.role}</p>
          </div>
          <div className="text-right">
            <span className="bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full">
              {recruit.status}
            </span>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-6 mb-8">
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-1">Email</h3>
            <p className="text-gray-900">{recruit.email}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-1">Phone</h3>
            <p className="text-gray-900">{recruit.phone}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-1">Date of Birth</h3>
            <p className="text-gray-900">{new Date(recruit.dateOfBirth).toLocaleDateString()}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-1">Driver's License</h3>
            <p className="text-gray-900">{recruit.driversLicense}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-1">Date Joined</h3>
            <p className="text-gray-900">{new Date(recruit.dateJoined).toLocaleDateString()}</p>
          </div>
          <div>
            <h3 className="text-sm font-medium text-gray-500 mb-1">Overall Progress</h3>
            <div className="flex items-center">
              <div className="flex-1 bg-gray-200 rounded-full h-2 mr-2">
                <div
                  className="bg-blue-600 h-2 rounded-full"
                  style={{ width: `${recruit.progress}%` }}
                />
              </div>
              <span className="text-sm text-gray-600">{recruit.progress}%</span>
            </div>
          </div>
        </div>

        <div className="relative">
          {recruit.onboardingSteps.map((step, index) => (
            <motion.div
              key={step.title}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              className="mb-8 last:mb-0"
            >
              <div className="flex items-center mb-4">
                <div
                  className={`flex-shrink-0 w-8 h-8 rounded-full flex items-center justify-center ${
                    step.status === 'completed'
                      ? 'bg-green-100'
                      : step.status === 'current'
                      ? 'bg-blue-100'
                      : 'bg-gray-100'
                  }`}
                >
                  {step.status === 'completed' ? (
                    <CheckCircle className="w-5 h-5 text-green-600" />
                  ) : step.status === 'current' ? (
                    <Clock className="w-5 h-5 text-blue-600" />
                  ) : (
                    <div className="w-3 h-3 bg-gray-400 rounded-full" />
                  )}
                </div>
                <h3 className="ml-4 text-lg font-semibold">{step.title}</h3>
              </div>
              <div className="ml-12">
                {step.items.map((item) => (
                  <div
                    key={item.name}
                    className="flex items-center justify-between mb-2 last:mb-0"
                  >
                    <div className="flex items-center space-x-3">
                      <div
                        className={`w-2 h-2 rounded-full ${
                          item.status === 'completed'
                            ? 'bg-green-400'
                            : 'bg-gray-300'
                        }`}
                      />
                      <span
                        className={
                          item.status === 'pending' ? 'text-gray-500' : 'text-gray-700'
                        }
                      >
                        {item.name}
                      </span>
                    </div>
                    {item.date && (
                      <span className="text-sm text-gray-500">
                        {new Date(item.date).toLocaleDateString()}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OnboardingFlow;